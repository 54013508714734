import react, {ChangeEvent, useRef, useState} from "react";
import styled from "styled-components";

import {
    AutoCompleteContainer,
    Input,
    AutoCompleteItem,
    AutoCompleteItemButton
} from "./styles";
import axios from "axios";
import {API_URL} from "../../api/constants";
const Root = styled.div`
  position: relative;
  width: 320px;
`;

export interface IData {
    name: string;
    id: number;
}
interface autoCompleteProps {
    iconColor?: string;
    inputStyle?: react.CSSProperties;
    optionsStyle?: react.CSSProperties;

    selected: any;
}

const keys = {
    ENTER: 13,
    UP: 38,
    DOWN: 40
};

export default function Autocomplete(props: autoCompleteProps) {
    const [search, setSearch] = useState({
        text: "",
        suggestions: [],
    });
    const [isComponentVisible, setIsComponentVisible] = useState(true);
    const [focusIndex, setFocusIndex] = useState(-1);
    const itemRefs = useRef<(HTMLElement | null)[]>([]);

    const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let suggestions: any[] = [];
        if (value.length > 2) {
            axios.defaults.headers.get['Accept'] = 'application/hal+json'
            axios.get(`${API_URL}/api/expense/search/items?pageSize=2500&sortBy=name&name=${value}`).then(data => {
                // this.setState({ items: data.data.content })
                suggestions = data.data.content;
                setIsComponentVisible(true);
                setFocusIndex(-1);

                // @ts-ignore
                setSearch({ suggestions, text: value });
            })
        } else {
            // @ts-ignore
            setSearch({ suggestions, text: value });
        }
    };

    const suggestionSelected = (value: IData) => {
        setIsComponentVisible(false);
        setFocusIndex(-1);

        setSearch({
            text: value.name,
            suggestions: []
        });
        props.selected(value);
    };

    const handleNavigation = (e: any) => {
        switch (e.keyCode) {
            case keys.ENTER:
                const item = suggestions[focusIndex];
                suggestionSelected(item)
                e.preventDefault();
                break;
            case keys.UP:
                if (focusIndex > 0) {
                    let newIndex = focusIndex - 1;
                    setFocusIndex(newIndex);
                    itemRefs.current[newIndex]?.scrollIntoView();
                }
                break;
            case keys.DOWN:
                if (focusIndex < suggestions.length - 1) {
                    let newIndex = focusIndex + 1;
                    setFocusIndex(newIndex);
                    itemRefs.current[newIndex]?.scrollIntoView();
                }
                break;
        }
    };

    const { suggestions } = search;

    return (
        <Root>
            <div
                onClick={() => setIsComponentVisible(false)}
                style={{
                    display: isComponentVisible ? "block" : "none",
                    width: "200vw",
                    height: "200vh",
                    backgroundColor: "transparent",
                    position: "fixed",
                    zIndex: 0,
                    top: 0,
                    left: 0
                }}
            />
            <div>
                <Input
                    id="itemId"
                    name="itemId"
                    autoComplete="off"
                    value={search.text}
                    onChange={onTextChanged}
                    onKeyDown={handleNavigation}
                    type={"text"}
                    style={props.inputStyle}
                />
            </div>
            {suggestions.length > 0 && isComponentVisible && (
                <AutoCompleteContainer style={props.optionsStyle}>
                    {suggestions.map((item: IData, index) => (
                        <AutoCompleteItem key={item.id} className={focusIndex === index ? 'active' : ''}>
                            <AutoCompleteItemButton
                                key={item.id}
                                onClick={() => suggestionSelected(item)}
                                onMouseEnter={() => setFocusIndex(index)}
                                className={focusIndex === index ? 'active' : ''}
                                ref={(ref : any) => itemRefs.current[index] = ref}
                            >
                                {item.name}
                            </AutoCompleteItemButton>
                        </AutoCompleteItem>
                    ))}
                </AutoCompleteContainer>
            )}
        </Root>
    );
}
